var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "route-manager-bar" },
    [
      _c("div", { staticClass: "route-manager-title" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("routeManager.routeManager"))),
        ]),
        _c("div", [
          _c("i", {
            staticClass: "hover-btn el-icon-upload",
            on: { click: _vm.importRoute },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "route-manager-search" },
        [
          _c(
            "el-input",
            {
              staticClass: "uav-input",
              staticStyle: { width: "100%" },
              attrs: {
                size: "small",
                placeholder: _vm.$t("routeManager.name"),
              },
              on: { change: _vm.searchHandle },
              model: {
                value: _vm.searchForm.name,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "name", $$v)
                },
                expression: "searchForm.name",
              },
            },
            [
              _c("template", { slot: "suffix" }, [
                _c("div", { staticClass: "search-icon-container" }, [
                  _c("i", {
                    staticClass: "el-icon-search",
                    on: { click: _vm.searchHandle },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "margin-separate" }),
          _c(
            "el-select",
            {
              staticClass: "uav-select",
              staticStyle: { width: "49%" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("mission.team"),
                size: "small",
              },
              on: { change: _vm.searchHandle },
              model: {
                value: _vm.searchForm.deptIds,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "deptIds", $$v)
                },
                expression: "searchForm.deptIds",
              },
            },
            _vm._l(_vm.deptList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "uav-select",
              staticStyle: { width: "49%", "margin-left": "2%" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("routeManager.type"),
                size: "small",
              },
              on: { change: _vm.searchHandle },
              model: {
                value: _vm.searchForm.uavType,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "uavType", $$v)
                },
                expression: "searchForm.uavType",
              },
            },
            _vm._l(_vm.routeTypeList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c("div", { staticClass: "margin-separate" }),
          _c(
            "el-select",
            {
              staticClass: "uav-select",
              staticStyle: { width: "49%" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("routeManager.UAVManufacturer"),
                size: "small",
              },
              on: { change: _vm.searchHandle },
              model: {
                value: _vm.searchForm.manufacturerId,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                },
                expression: "searchForm.manufacturerId",
              },
            },
            _vm._l(_vm.manufacturerList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "uav-select",
              staticStyle: { width: "49%", "margin-left": "2%" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("routeManager.uavType"),
                size: "small",
              },
              on: { change: _vm.searchHandle },
              model: {
                value: _vm.searchForm.uavNumber,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "uavNumber", $$v)
                },
                expression: "searchForm.uavNumber",
              },
            },
            _vm._l(_vm.uavNumberList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "route-item-box",
          attrs: {
            "element-loading-text": _vm.$t("loading"),
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
        },
        _vm._l(_vm.routeList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "route-item",
              class: _vm.selectRoute.id === item.id ? "route-item-active" : "",
              on: {
                mouseleave: _vm.leaveRecord,
                click: function ($event) {
                  return _vm.chooseRoute(item)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "route-title" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: item.name, placement: "top" } },
                    [
                      _c("div", { staticClass: "route-describe-mane" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "control-icon" }, [
                    item.id === _vm.selectRoute.id
                      ? _c("i", {
                          staticClass: "el-icon-edit-outline",
                          staticStyle: { "margin-right": "4px" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.editRoute(item)
                            },
                          },
                        })
                      : _vm._e(),
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#FFFFFF" },
                        attrs: { href: item.routeFileUrl },
                      },
                      [
                        item.id === _vm.selectRoute.id
                          ? _c("i", {
                              staticClass: "el-icon-download",
                              staticStyle: { "margin-right": "4px" },
                            })
                          : _vm._e(),
                      ]
                    ),
                    item.id === _vm.selectRoute.id
                      ? _c("i", {
                          staticClass: "el-icon-delete",
                          class: _vm.deleting ? "bgc-red" : "",
                          staticStyle: { "margin-right": "4px" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(item)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "route-task" }, [
                _vm._v(_vm._s(item.routeTypeName)),
              ]),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content:
                      item.manufacturerName +
                      " -- " +
                      (item.uavNumber ? item.uavNumber : "--"),
                    placement: "right",
                  },
                },
                [
                  _c("div", { staticClass: "route-describe" }, [
                    _c("span", [_vm._v(_vm._s(item.manufacturerName))]),
                    item.uavNumber
                      ? _c("span", [
                          _vm._v(
                            "\n              ,  " +
                              _vm._s(item.uavNumber) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("div", { staticClass: "route-describe" }, [
                _vm._v(_vm._s(item.deptNames)),
              ]),
              _c("div", { staticClass: "route-time" }, [
                _vm._v(_vm._s(item.createTime)),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          staticClass: "editDialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.editDialog,
            width: "748px",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            top: "20vh",
            "before-close": _vm.handleCloseEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "editForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.editForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                    inline: "",
                  },
                },
                [
                  _c("div", { staticClass: "blue-title" }, [
                    _vm._v(_vm._s(_vm.$t("baseInfo"))),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.name") + "：",
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "uav-input",
                        staticStyle: { width: "248px" },
                        attrs: {
                          size: "small",
                          placeholder: _vm.$t("inputPlease"),
                        },
                        model: {
                          value: _vm.editForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "name", $$v)
                          },
                          expression: "editForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.UAVManufacturer") + "：",
                        prop: "manufacturerId",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.manufacturerList,
                          size: "small",
                          disabled: this.editAction === "edit",
                          clearable: true,
                          placeholder: _vm.$t("selectPlease"),
                        },
                        on: { change: _vm.manufacturerChange },
                        model: {
                          value: _vm.editForm.manufacturerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "manufacturerId", $$v)
                          },
                          expression: "editForm.manufacturerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showUAVHangar
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("routeManager.associationHangar") + "：",
                            prop: "associationHangar",
                          },
                        },
                        [
                          _c("select-tree", {
                            staticClass: "uav-select",
                            staticStyle: { width: "248px" },
                            attrs: {
                              data: _vm.hangarList,
                              size: "small",
                              clearable: true,
                              disabled: this.editAction === "edit",
                              placeholder: _vm.$t("selectPlease"),
                            },
                            model: {
                              value: _vm.editForm.associationHangar,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "associationHangar", $$v)
                              },
                              expression: "editForm.associationHangar",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showUAVModel
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("routeManager.UAVModel") + "：",
                            prop: "uavModel",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "uav-input",
                            staticStyle: { width: "248px" },
                            attrs: {
                              disabled: this.editAction === "edit",
                              size: "small",
                              placeholder: _vm.$t("inputPlease"),
                            },
                            on: { blur: _vm.uavModelBlur },
                            model: {
                              value: _vm.editForm.uavModel,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "uavModel", $$v)
                              },
                              expression: "editForm.uavModel",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.uavType") + "：",
                        prop: "uavType",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.uavTypeList,
                          size: "small",
                          clearable: true,
                          disabled: this.editAction === "edit",
                          placeholder: _vm.$t("selectPlease"),
                        },
                        on: { change: _vm.uavTypeChange },
                        model: {
                          value: _vm.editForm.uavType,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "uavType", $$v)
                          },
                          expression: "editForm.uavType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.uavNumber") + "：",
                        prop: "uavNumber",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.uavNumberList,
                          size: "small",
                          clearable: true,
                          disabled:
                            _vm.editAction === "edit" ||
                            !_vm.editForm.manufacturerId ||
                            !_vm.editForm.uavType,
                          placeholder: _vm.$t("selectPlease"),
                        },
                        model: {
                          value: _vm.editForm.uavNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "uavNumber", $$v)
                          },
                          expression: "editForm.uavNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.podType") + "：",
                        prop: "podType",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.podTypeList,
                          size: "small",
                          clearable: true,
                          disabled: this.editAction === "edit",
                          placeholder: _vm.$t("selectPlease"),
                        },
                        model: {
                          value: _vm.editForm.podType,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "podType", $$v)
                          },
                          expression: "editForm.podType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.routeType") + "：",
                        prop: "routeType",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.routeTypeList,
                          size: "small",
                          disabled: this.editAction === "edit",
                          placeholder: _vm.$t("selectPlease"),
                        },
                        model: {
                          value: _vm.editForm.routeType,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "routeType", $$v)
                          },
                          expression: "editForm.routeType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mission.team") + "：",
                        prop: "deptIds",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.deptList,
                          size: "small",
                          multiple: true,
                          placeholder: _vm.$t("selectPlease"),
                        },
                        model: {
                          value: _vm.editForm.deptIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "deptIds", $$v)
                          },
                          expression: "editForm.deptIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("routeManager.status") + "：",
                        prop: "status",
                      },
                    },
                    [
                      _c("select-tree", {
                        staticClass: "uav-select",
                        staticStyle: { width: "248px" },
                        attrs: {
                          data: _vm.openList,
                          size: "small",
                          clearable: true,
                          disabled: this.editAction === "edit",
                          placeholder: _vm.$t("selectPlease"),
                        },
                        model: {
                          value: _vm.editForm.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "status", $$v)
                          },
                          expression: "editForm.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.editAction === "upload"
                    ? _c("div", { staticClass: "blue-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("fileInfo")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.editAction === "upload"
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: _vm.$t("uploadFile") + "：" },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "updateFile",
                              staticStyle: { width: "248px" },
                              attrs: {
                                limit: 1,
                                multiple: false,
                                "auto-upload": true,
                                "http-request": _vm.httpRequest,
                                "on-remove": _vm.handleRemove,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("Upload")) +
                                      "\n              " +
                                      _vm._s(_vm.getFileType) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm.getFileType === ".kmz" + _vm.$t("file")
                                ? _c(
                                    "div",
                                    { attrs: { slot: "tip" }, slot: "tip" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("routeManager.importDesc")
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("mission.uploadRoute") + "：" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticStyle: { width: "248px" },
                          attrs: {
                            headers: _vm.headers,
                            action:
                              "/api/blade-resource/oss/endpoint/put-file?business=route&module=project&system=cloud",
                            "show-file-list": false,
                            "on-remove": _vm.ImageHandleRemove,
                            "before-remove": _vm.ImageBeforeRemove,
                            "on-success": _vm.ImageeHandleSuccess,
                            "before-upload": _vm.ImageHandleBeforeUpload,
                          },
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar-img-box",
                                attrs: { src: _vm.imageUrl },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v(_vm._s(_vm.$t("mission.ImageSizeLimit")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseEdit },
                },
                [_vm._v(_vm._s(_vm.$t("btnCancel")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.submitLoading,
                      expression: "submitLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitRoute },
                },
                [_vm._v(_vm._s(_vm.$t("btnOk")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }