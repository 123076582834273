<template>
  <div class="route-manager-bar">
    <div class="route-manager-title">
      <div class="title">{{$t('routeManager.routeManager')}}</div>
      <div> 
        <i
          class="hover-btn el-icon-upload"
          @click="importRoute"
          >
        </i>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="route-manager-search">
      <el-input
        class="uav-input"
        size="small"
        @change="searchHandle"
        style="width: 100%;"
        v-model="searchForm.name"
        :placeholder="$t('routeManager.name')"
      >
        <template slot="suffix">
          <div class="search-icon-container">
            <i class="el-icon-search" @click="searchHandle"></i>
          </div>
        </template>
      </el-input>
      <div class="margin-separate"></div>
      <!-- 所属团队 -->
      <el-select
        class="uav-select"
        clearable
        v-model="searchForm.deptIds"
        @change="searchHandle"
        style="width: 49%;"
        :placeholder="$t('mission.team')"
        size="small">
        <el-option
          v-for="item in deptList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 航线类型 -->
      <el-select
        class="uav-select"
        clearable
        v-model="searchForm.uavType"
        @change="searchHandle"
        style="width: 49%; margin-left: 2%;"
        :placeholder="$t('routeManager.type')"
        size="small">
        <el-option
          v-for="item in routeTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="margin-separate"></div>
      <!-- 飞机品牌 -->
      <el-select
        class="uav-select"
        clearable
        v-model="searchForm.manufacturerId"
        @change="searchHandle"
        style="width: 49%;"
        :placeholder="$t('routeManager.UAVManufacturer')"
        size="small">
        <el-option
          v-for="item in manufacturerList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 飞机型号 -->
      <el-select
        class="uav-select"
        clearable
        v-model="searchForm.uavNumber"
        @change="searchHandle"
        style="width: 49%; margin-left: 2%;"
        :placeholder="$t('routeManager.uavType')"
        size="small">
        <el-option
          v-for="item in uavNumberList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="route-item-box"
      v-loading="loading"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
      <div
        class="route-item"
        v-for="item in routeList"
        :key="item.id"
        :class="selectRoute.id === item.id? 'route-item-active': ''"
        @mouseleave="leaveRecord"
        @click="chooseRoute(item)">
        <div class="route-title">
          <el-tooltip :content="item.name" placement="top">
              <div class="route-describe-mane">{{item.name}}</div>
          </el-tooltip>
          <span class="control-icon">
            <i
              @click.stop="editRoute(item)"
              v-if="item.id===selectRoute.id"
              style="margin-right: 4px"
              class="el-icon-edit-outline">
            </i>
            <a style="color: #FFFFFF" :href="item.routeFileUrl">
              <i
                v-if="item.id===selectRoute.id"
                style="margin-right: 4px"
                class="el-icon-download">
              </i>
            </a>
            <i
              @click.stop="deleteItem(item)"
              v-if="item.id===selectRoute.id"
              :class="deleting? 'bgc-red': ''"
              style="margin-right: 4px"
              class="el-icon-delete">
            </i>
          </span>
        </div>
        <div class="route-task">{{ item.routeTypeName }}</div>
        <el-tooltip
          :content="`${item.manufacturerName} -- ${item.uavNumber? item.uavNumber: '--'}`"
          placement="right">
            <div class="route-describe">
              <span>{{ item.manufacturerName }}</span>
              <span v-if="item.uavNumber">
                ,  {{ item.uavNumber }}
              </span>
            </div>
        </el-tooltip>
        <div class="route-describe">{{ item.deptNames }}</div>
        <div class="route-time">{{ item.createTime }}</div>
      </div>
    </div>
    <el-dialog 
      class="editDialog"
      :title="dialogTitle" 
      :visible.sync="editDialog"
      width="748px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      top="20vh"
      :before-close="handleCloseEdit">
      <div class="content">
        <el-form 
          :model="editForm"
          :rules="rules"
          ref="editForm"
          label-width="auto"
          inline
          class="demo-ruleForm">
          <div class="blue-title">{{$t('baseInfo')}}</div>
          <!-- 航线名称 -->
          <el-form-item
            :label="`${$t('routeManager.name')}：`"
            prop="name">
            <el-input
              class="uav-input"
              style="width: 248px;"
              v-model="editForm.name" 
              size="small"
              :placeholder="$t('inputPlease')">
            </el-input>
          </el-form-item>
          <!-- 飞机品牌 -->
          <el-form-item
            :label="`${$t('routeManager.UAVManufacturer')}：`"
            prop="manufacturerId">
            <select-tree
              class="uav-select"
              v-model="editForm.manufacturerId"
              :data="manufacturerList"
              size="small"
              :disabled="this.editAction === 'edit'"
              :clearable="true"
              style="width: 248px"
              @change="manufacturerChange"
              :placeholder="$t('selectPlease')"
            />
          </el-form-item>
          <!-- 关联机库 -->
          <el-form-item
            :label="`${$t('routeManager.associationHangar')}：`"
            v-if="showUAVHangar"
            prop="associationHangar">
            <select-tree
              class="uav-select"
              v-model="editForm.associationHangar"
              :data="hangarList"
              size="small"
              :clearable="true"
              :disabled="this.editAction === 'edit'"
              style="width: 248px"
              :placeholder="$t('selectPlease')"
            />
          </el-form-item>
          <!-- 飞机指纹 -->
          <el-form-item
            :label="`${$t('routeManager.UAVModel')}：`"
            v-if="showUAVModel"
            prop="uavModel">
            <el-input
              @blur="uavModelBlur"
              class="uav-input"
              style="width: 248px;"
              :disabled="this.editAction === 'edit'"
              v-model="editForm.uavModel" 
              size="small"
              :placeholder="$t('inputPlease')">
            </el-input>
          </el-form-item>
          <!-- 飞机类型 -->
          <el-form-item
            :label="`${$t('routeManager.uavType')}：`"
            prop="uavType">
            <select-tree
              class="uav-select"
              v-model="editForm.uavType"
              :data="uavTypeList"
              size="small"
              :clearable="true"
              @change="uavTypeChange"
              :disabled="this.editAction === 'edit' "
              style="width: 248px"
              :placeholder="$t('selectPlease')"
            />
          </el-form-item>
          <!-- 飞机型号 -->
          <el-form-item
            :label="`${$t('routeManager.uavNumber')}：`"
            prop="uavNumber">
            <select-tree
              class="uav-select"
              v-model="editForm.uavNumber"
              :data="uavNumberList"
              size="small"
              :clearable="true"
              :disabled="editAction === 'edit' || !editForm.manufacturerId || !editForm.uavType "
              style="width: 248px"
              :placeholder="$t('selectPlease')"
            />
          </el-form-item>
          <!-- 载荷类型 -->
          <el-form-item
            :label="`${$t('routeManager.podType')}：`"
            prop="podType">
            <select-tree
              class="uav-select"
              v-model="editForm.podType"
              :data="podTypeList"
              size="small"
              :clearable="true"
              :disabled="this.editAction === 'edit' "
              style="width: 248px"
              :placeholder="$t('selectPlease')"
            />
          </el-form-item>
          <!-- 航线类型 -->
          <el-form-item
            :label="`${$t('routeManager.routeType')}：`"
            prop="routeType">
            <select-tree
                class="uav-select"
                :data="routeTypeList"
                style="width: 248px;"
                size="small"
                :disabled="this.editAction === 'edit'"
                :placeholder="$t('selectPlease')"
                v-model="editForm.routeType"
              >
              </select-tree>
          </el-form-item>
          <!-- 所属团队 -->
          <el-form-item
            :label="`${$t('mission.team')}：`"
            prop="deptIds">
            <select-tree
              class="uav-select"
              v-model="editForm.deptIds"
              :data="deptList"
              size="small"
              :multiple="true"
              style="width: 248px;"
              :placeholder="$t('selectPlease')">
            </select-tree>
          </el-form-item>
          <!-- 是否公开 -->
          <el-form-item
            :label="`${$t('routeManager.status')}：`"
            prop="status">
            <select-tree
              class="uav-select"
              v-model="editForm.status"
              :data="openList"
              size="small"
              :clearable="true"
              :disabled="this.editAction === 'edit' "
              style="width: 248px"
              :placeholder="$t('selectPlease')"
            />
          </el-form-item>
          <!-- 标题 -->
          <div
            v-if="editAction==='upload'"
            class="blue-title">
            {{$t('fileInfo')}}
          </div>
          <!-- 上传 -->
          <el-form-item
            class="is-required"
            v-if="editAction==='upload'"
            :label="`${$t('uploadFile')}：`">
            <el-upload
              style="width: 248px"
              ref="updateFile"
              :limit="1"
              :multiple="false"
              :auto-upload="true"
              :http-request="httpRequest"
              :on-remove="handleRemove"
              :file-list="fileList">
              <el-button size="small" type="primary">
                {{$t('Upload')}}
                {{getFileType}}
              </el-button>
              <div
                v-if="getFileType === `.kmz${$t('file')}`"
                slot="tip">
                {{$t('routeManager.importDesc')}}
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            :label="`${$t('mission.uploadRoute')}：`">
            <el-upload
              style="width: 248px;"
              :headers="headers"
              :action="`/api/blade-resource/oss/endpoint/put-file?business=route&module=project&system=cloud`"
              :show-file-list="false"
              :on-remove="ImageHandleRemove"
              :before-remove="ImageBeforeRemove"
              :on-success="ImageeHandleSuccess"
              :before-upload="ImageHandleBeforeUpload"
              >
              <img v-if="imageUrl" :src="imageUrl" class="avatar-img-box">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">{{ $t('mission.ImageSizeLimit') }}</div>
            </el-upload>
          </el-form-item>
          <!-- 航线截图 -->
          <!-- <el-form-item
            :label="`${$t('routeManager.screenshot')}：`">
            <el-image
              style="width: 220px; height: 124px"
              :src="thumbnail" 
              :preview-src-list="[thumbnail]">
            </el-image>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCloseEdit">{{ $t('btnCancel') }}</el-button>
        <el-button size="small" v-loading="submitLoading" type="primary" @click="submitRoute">{{ $t('btnOk') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from "vuex";
  import {
    submitRoute,
    removeRoute,
    getRouteList,
    getDeptList,
    getEquipment,
    getequipmentManagement
  } from '@/api/project/airRoute'
  import {
    getHangarByManufacturerId
  } from "@/api/hangar/index";
  import { getDictTreeByCode } from '@/api/system/dict'
  import website from '@/config/website';
  import { getEntity } from '@/api/phugiacloud/equipmentManagement'
  import { getEquipmentTypeList } from '@/api/hangar/index'
  import { getToken } from "@/util/auth";


  export default {
    name: "operationBar",
    data() {
      return {
        submitLoading: false,
        loading: true,
        // 搜索
        searchForm: {
          name: '',
          deptId: '',
          routeType: '',
          manufacturerId: '',
          uavNumber: '',
        },
        // 增加标识
        editAction: 'add',
        // 航线
        routeList: [],
        // 部门列表
        deptList: [],
        // 航线类型列表
        routeTypeList: [],
        // 吊舱类型列表
        podTypeList: [],
        // 机库列表
        hangarList: [],
        // 品牌列表
        manufacturerList: [],
        // 飞机型号
        uavNumberList: [],
        // 飞机类型 
        uavTypeList: [],
        // 公开
        openList: [
          {
            label: '是',
            value: 1
          },
          {
            label: '否',
            value: 0
          }
        ],
        // 删除缓冲
        deleting: false,
        // 编辑弹窗
        editDialog: false,
        // 编辑表单
        editForm: {},
        // 表单验证
        rules: {
          // 航线名称
          name: [
            { required: true, message: this.$t('inputPlease'), trigger: 'blur' }
          ],
          // 飞机品牌
          manufacturerId: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' }
          ],
          // 关联机库
          associationHangar: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' }
          ],
          // 飞机类型
          uavType: [
            { required: true, message: this.$t('inputPlease'), trigger: 'blur' }
          ],
          // 飞机指纹
          uavModel: [
            { required: true, message: this.$t('inputPlease'), trigger: 'blur' }
          ],
          // 飞机型号
          uavNumber: [
            { required: true, message: this.$t('inputPlease'), trigger: 'blur' }
          ],
          // 载荷类型
          podType: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' }
          ],
          // 航线类型
          routeType: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' }
          ],
          // 所属团队
          deptIds: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' }
          ],
          // 是否公开
          status: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' }
          ],
        },
        // 图片地址
        imageUrl: '',
        //
        fileList: [],
        //
        headers: {
          "Blade-Auth": "bearer " + getToken(),
          Authorization: `Basic ${Base64.encode(
            `${website.clientId}:${website.clientSecret}`
          )}`,
        }
      }
    },
    computed: {
      ...mapGetters([
        'selectRoute'
      ]),
      getFileType() {
        const node = this.manufacturerList.find(item=>{
          return item.value == this.editForm.manufacturerId
        })
        if (node && node.code) {
          switch (node.code) {
            case 'aossci1.0':
              return '.mission' + this.$t('file')
            case 'dji':
              return '.kmz' + this.$t('file')
            case 'aossci':
              return '.geojson' + this.$t('file')
            case 'aossci3.0':
              return '.kml' + this.$t('file')
            default:
              return '.geojson' + this.$t('file')
          }
        }
        return '.geojson' + this.$t('file')
      },
      showUAVModel() {
        let node = this.manufacturerList.find(item=>{
          return this.editForm.manufacturerId === item.value
        })
        if (node && node.code === 'aossci') {
          return true
        }
        return false
      },
      showUAVHangar() {
        let node = this.manufacturerList.find(item=>{
          return this.editForm.manufacturerId === item.value
        })
        if (node && node.code === 'aossci3.0') {
          return true
        }
        return false
      },
      dialogTitle() {
        switch (this.editAction) {
          case 'upload':
            return this.$t('Import') + this.$t('routeManager.route')
          default:
            return ''
        }
      },
      thumbnail() {
        return this.editForm.thumbnail? this.editForm.thumbnail: 'img/route-no.png'
      }
    },
    mounted() {
      this.initEditFrom()
      this.getDeptList()
      this.getRouteTypeList()
      this.getManufacturerList()
      // this.getModelList()
      this.getRouteList()
      this.getUavTypeList()
    },
    methods: {
      ...mapMutations([
        'SET_SELECT_ROUTE'
      ]),

      // 所属部门列表
      getDeptList() {
        getDeptList().then(res=>{
          if (res.data.code === 200) {
            this.deptList = this.filterDeptTree(res.data.data);
          }
        })
      },

      // 航线类型
      getRouteTypeList() {
        getDictTreeByCode({
          code: 'routeType'
        }).then(res=>{
          if (res.data.code === 200) {
            this.routeTypeList = res.data.data.map(item=>{
              return {
                value: item.dictKey,
                label: item.dictValue
              }
            })
          }
        })
      },

      // 获取无人机类型列表
      getUavTypeList() {
        getDictTreeByCode({
          code: 'equipment_type'
        }).then(res=>{
          this.uavTypeList = res.data.data.find(item=>{
            return item.dictKey === 'uav'
          }).children.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          })
          this.podTypeList = res.data.data.find(item=>{
            return item.dictKey === 'pod'
          }).children.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          })
        })
      },

      // 品牌列表
      getManufacturerList() {
        getEquipment({
          size: 9999,
          current: 1
        }).then(res=>{
          if (res.data.code === 200) {
            this.manufacturerList = res.data.data.records.map(item=>{
              return {
                value: item.id,
                label: item.name,
                code: item.code
              }
            })
          }
        })
      },

      // 飞机型号列表
      getModelList() {
        getEquipmentTypeList({
          childValue: this.editForm.uavType,
          parentKey: this.manufacturerList.find(item => item.value === this.editForm.manufacturerId).code
        }).then(res=>{
          if (res.data.code === 200) {
            this.uavNumberList = res.data.data.map(item=>{
              return {
                value: item.code,
                label: item.dictKey
              }
            })
          }
        })
      },

      // 搜索
      searchHandle() {
        this.getRouteList()
      },

      // 选择航线
      chooseRoute(node) {
        this.$EventBus.$emit('route-manager-click', node)
        this.SET_SELECT_ROUTE(node)
      },

      //
      getRouteList() {
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.searchForm))
        params.current = 1
        params.size = 9999
        getRouteList(params).then(res=>{
          if (res.data.code === 200) {
            this.routeList = res.data.data.records
          }
        }).finally(()=>{
          this.loading = false
        })
      },

      //
      deleteItem(node) {
        if (!this.deleting) {
          this.deleting = true
          return
        }
        const id = node.id
        removeRoute({
          ids: id
        }).then(()=>{
          this.getRouteList()
        })
      },

      // 
      leaveRecord() {
        this.deleting = false
      },

      // 关闭编辑
      handleCloseEdit() {
        if (this.$refs.updateFile) {
          this.$refs.updateFile.clearFiles()
        }
        this.$refs.editForm.clearValidate()
        this.initEditFrom()
        this.editDialog = false
      },

      // 提交航线
      submitRoute() {
        this.$refs.editForm.validate((value)=>{
          if (value) {
            if (!this.editForm.routeFileUrl) {
              return this.$message.warning('请上传视频文件')
            }
            if (this.editAction === 'upload' || this.editAction === 'edit') {
              this.submitLoading = true
              let deptNameList = this.getDeptNames(this.editForm.deptIds)
              this.editForm.deptNames = deptNameList.join(',')
              this.editForm.manufacturerName = this.manufacturerList.find(item=>{
                return this.editForm.manufacturerId === item.value
              }).label
              // this.editForm.uavNumberName = this.uavNumberList.find(item=>{
              //   return this.editForm.uavNumber === item.value
              // }).label
              submitRoute(this.editForm).then(res=>{
                this.submitLoading = false
                this.$message.success(res.data.msg)
                this.handleCloseEdit()
                this.getRouteList()
              }).finally(()=>{
                this.submitLoading = false
              })
            }
          }
        })
      },

      // 编辑航线
      editRoute(node) {
        this.editAction = 'edit'
        let data = JSON.parse(JSON.stringify(node))
        delete data.geom
        console.log(data);
        this.editForm = data
        this.editDialog = true
      },

      // 导入航线
      importRoute() {
        this.editAction = 'upload'
        this.editDialog = true
      },

      // 部门树数据过滤
      filterDeptTree(tree) {
        return tree.map((item) => {
          item.label = item.deptName;
          item.value = item.id;
          if (item.children && Array.isArray(item.children)) {
            item.children = this.filterDeptTree(item.children);
          }
          return item;
        });
      },

      // 品牌下拉改变
      manufacturerChange(value) {
        var node = this.manufacturerList.find((item) => {
          return value === item.value;
        });
        if (!node) {
          return
        }
        switch (node.code) {
          case 'aossci1.0':
            this.editForm.uavModel = ''
            this.hangarList = []
            break;
          case 'aossci2.0':
            this.hangarList = []
            break;
          case 'aossci3.0':
            this.editForm.uavModel = ''
            this.editForm.associationHangar = ''
            this.getHangarByManufacturerId(node.value)
            break;
          case 'dji':
            this.editForm.uavModel = ''
            this.hangarList = []
            break;
          default:
            break;
        }
        if (this.editForm.manufacturerId && value) {
          this.uavNumberList = []
          this.getModelList()
        }
      },

      // 飞机类型下拉
      uavTypeChange(value) {
        if (this.editForm.manufacturerId && value) {
          this.uavNumberList = []
          this.getModelList()
        }
      },

      // 获取机库
      getHangarByManufacturerId(id) {
        getHangarByManufacturerId({
          manufacturerId: id
        }).then((res) => {
          if (res.data.code === 200) {
            this.hangarList = res.data.data.map((item) => {
              return {
                label: item.name,
                value: item.id,
                equipmentName: item.equipmentName,
                airPlaneID: item.equipmenId,
                hangarSN: item.serialNumber,
              };
            });
          }
        });
      },

      // 指纹失去焦点
      uavModelBlur() {
        getEntity({
          signature: this.editForm.uavModel
        }).then(res=>{
          if (res.data.code === 200) {
            this.editForm.uavType = res.data.data
          }
        })
      },

      // 删除文件
      handleRemove() {
        this.editForm.routeFileUrl = ''
      },
      
      // 上传文件
      httpRequest(node) {
        var formData = new FormData();
        formData.append('file', node.file)
        const url = `/api/blade-resource/oss/endpoint/upload-file`
        this.submitLoading = true
        axios({
          baseURL: '',
          url,
          method: 'post',
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryn8D9asOnAnEU4Js0",
            'Authorization': `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`
          }
        }).then(res=>{
          if (res.data.code === 200) {
            this.editForm.routeFileUrl = res.data.data.link
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(()=>{
        }).finally(()=>{
          this.submitLoading = false
        })
      },

      // 初始化编辑表单
      initEditFrom() {
        this.editForm = {
          // 名称
          name: '',
          // 品牌名称
          manufacturerId: '',
          // 关联机库
          associationHangar: '',
          // 飞机指纹
          uavModel: '',
          // 飞机类型
          uavType: '',
          // 飞机型号
          uavNumber: '',
          // 部门
          deptIds: [],
          // 载荷类型
          podType: '',
          // 航线类型
          routeType: '',
          // 是否开放
          status: 0,
          // 上传文件地址
          routeFileUrl: '',
          // 航线图片地址
          thumbnail: ''
        }
      },

      // 过滤部门名称
      getDeptNames(deptIds, deptList) {
        deptList = deptList? deptList: this.deptList
        let list = []
        for (var i = 0; i < deptList.length; i++) {
          var item = deptList[i]
          if (deptIds.indexOf(item.value) > -1) {
            list.push(item.label)
          }
          if (item.children && Array.isArray(item.children)) {
            list = list.concat(this.getDeptNames(deptIds, item.children))
          }
        }
        return list
      },

      // 删除图片
      ImageHandleRemove() {
        this.editForm.thumbnail = ''
      },

      // 删除图片前
      ImageBeforeRemove() {

      },

      // 上传成功回调
      ImageeHandleSuccess(res) {
        if (res.code === 200) {
          const data = res.data
          const link = data.link
          this.imageUrl = link
          this.editForm.thumbnail = link
        }
      },

      // 上传前回调
      ImageHandleBeforeUpload() {

      }
    },
    beforeDestroy() {}
  };
</script>
<style lang="scss">
.route-manager-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 346px;
  left: 2px;
  top: 30px;
  background-color: rgba(30, 34, 42, 1);
  .route-manager-title {
    padding: 17px 22px 12px 22px;
    font-size: 14px;
    line-height: 20px;
    font-family: Microsoft Yahei UI;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #030303;
    display: flex;
    align-items: center;
    .title {
      flex: 1;
    }  
  }
  .route-manager-search {
    padding: 12px 18px 12px 22px;
    border-bottom: 1px solid #030303;
  }
  .uav-input.is-disabled {
    .el-input__inner {
      background-color: #000000 !important;
      border: 1px solid gray;
    }
  }
  .uav-select {
    .el-input input {
      height: 30px;
    }
  }
  .uav-date-range {
    background-color: #030303;
    border-color: #444;
    .el-input__icon{
      display: none;
    }
    .el-range-input {
      background-color: transparent;
      color: #ffffff;
      width: 45%;
    }
  }
  input::placeholder {
    color: #444;
  }
  .search-icon-container {
    line-height: 24px;
    font-size: 24px;
    cursor: pointer;
    .el-icon-search{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .margin-separate {
    margin: 20px 0px;
  }
  .route-item-box{
    flex-grow: 1;
    overflow: auto;
    .route-item {
        cursor: pointer;
        height: 148px;
        box-sizing: border-box;
        border-bottom: 1px solid #030303;
        padding: 16px 10px;
        color: rgba(153, 153, 153, 0.79);
        .route-title {
            font-size: 14px;
            font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
        }
        .route-task {
            height: 15px;
            margin-top: 16px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            color: rgba(153,153,153,0.79);
            line-height: 15px;
        }
        .route-describe-mane {
            color: #FFFFFF;
            height: 15px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            max-width: 250px;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .route-describe {
            height: 15px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .route-time {
            height: 15px;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-show {
            color: #3573FF;
        }
    }
  }
  .route-item-active {
      background-color: #3573FF33;
  }
  .bgc-red {
    background-color: #f00;
  }
  .editDialog {
    .el-dialog {
      background-color: rgba(30, 34, 42, 1);
      .el-dialog__header {
        .el-dialog__title {
          font-size: 16px;
          color: #FFFFFF;
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #ffffff;
        }
      }
      .el-dialog__body {
        padding: 15px 20px;
        .content {
          .el-form-item__label {
            color: #ffffff;
          }
          .el-input__inner {
            font-size: 14px;
            color: #ffffff;
            background-color: transparent;
            &::placeholder {
              color:  rgba(153, 153, 153, 0.79);
            }
          }
        }
      }
    }
  }
  .hover-btn {
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
  }
  .blue-title {
    border-left: 5px #409EFF solid;
    padding-left: 5px;
    margin-bottom: 5px;
    color: #FFFFFF;
  }
  .avatar-img-box {
    width: 100px;
    height: 100px;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 2px #8c939d dashed;
  }
}
</style>
