<template>
  <div class="container normal routeManager-bar-box">
    <operationBar/>
    <CusiumMap class="routeManager-bar-lastItem" :is3D="true" />
  </div>
</template>

<script>
import CusiumMap from "@/components/cesium/index";
import operationBar from "./operationBar/";

export default {
  // 这里和后台的code字段对应，用于做路由缓存
  name: "route-manager",
  components: {
    CusiumMap,
    operationBar
  },
  data() {
    return {}
  },
  computed: {}
};
</script>

<style lang="scss">
.routeManager-bar-box {
  width: 100%;
  height: 100%;
  display: flex;
  .routeManager-bar-lastItem {
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
